// @ts-nocheck

import { useEffect, useState } from 'react';
import JsSIP from 'jssip';
import { defineMessages, useIntl } from "react-intl";
import audioPlayer from '../lib/audioPlayer';
import { useModal } from "../../../app/hooks/useModal";
import { useSiteVersion } from "../../../app/hooks/useSiteVersion";

const UseSipMessages = defineMessages({
  errorVoiceOverLTE: {
    id: "UseSipMessages.errorVoiceOverLTE",
    defaultMessage: "Voice Over LTE doesn’t work"
  }
});

export const useSip = () => {
  const intl = useIntl();
  const [session, setSession] = useState<JsSIP.RTCSession | null>(null);
  const [callHasAcceptedByOtherSide, setCallHasAcceptedByOtherSide] = useState(false);
  const [hasOutgoingCall, setHasOutgoingCall] = useState(false);
  const [hasIncomingCall, setHasIncomingCall] = useState(false);
  const [ua, setUa] = useState<JsSIP.UA | null>(null);
  const [loading, setLoading] = useState(false);
  const { handleHideModal } = useModal();
  const { siteVersion } = useSiteVersion();

  const params = new URLSearchParams(window.location.search);
  const displayName = params.get('displayName') || '100';
  const uri = params.get('uri') || 'sip:100@pbx.telecorn.com';
  const targetUri = params.get('targetUri') || '200';
  const password = params.get('password') || 'test100test';
  const socket = params.get('socket') || 'wss://pbx.telecorn.com:7443';

  useEffect(() => {
    console.log('CONFIG', { displayName, uri, targetUri, password, socket });
  }, []);

  useEffect(() => {
    if (ua || siteVersion === 'ru') return;

    const wsSocket = new JsSIP.WebSocketInterface(socket);
    const userAgent = new JsSIP.UA({
      uri,
      password,
      display_name: displayName,
      sockets: [wsSocket],
      session_timers: true,
      use_preloaded_route: false,
    });

    setUa(userAgent);
    userAgent.start();

    userAgent.on('connected', () => console.log('Connected to WebSocket'));
    userAgent.on('disconnected', () => {
      console.log('Disconnected from WebSocket');
      resetState();
    });

    userAgent.on('newRTCSession', (e) => {
      const newSession = e.session;
      if (newSession.direction === 'incoming') {
        handleIncomingCall(newSession);
      }
    });

    return () => {
      if (ua) {
        ua.stop();
        resetState();
        audioPlayer.stop();
      }
    };
  }, [ua, uri, password, displayName, socket]);

  const resetState = () => {
    setSession(null);
    setHasOutgoingCall(false);
    setHasIncomingCall(false);
    setCallHasAcceptedByOtherSide(false);
  };

  const handleIncomingCall = (incomingSession) => {
    if (session) {
      incomingSession.terminate({ status_code: 486, reason_phrase: 'Busy Here' });
      return;
    }

    setSession(incomingSession);
    setHasIncomingCall(true);

    if (!audioPlayer.isPlaying('ringing')) {
      audioPlayer.play('ringing');
    }

    incomingSession.on('accepted', () => {
      audioPlayer.stop('ringing');
      setCallHasAcceptedByOtherSide(true);
      setHasIncomingCall(false);
    });

    incomingSession.on('confirmed', () => {
      const connection = incomingSession.connection;
      if (connection) {
        connection.ontrack = (event) => {
          const remoteStream = event.streams[0];
          const audioElement = document.getElementById('remoteAudio') as HTMLAudioElement;
          if (audioElement && remoteStream) {
            audioElement.srcObject = remoteStream;
            audioElement
              .play()
              .catch((error) => console.error('Ошибка воспроизведения аудио:', error));
          }
        };
      }
    });

    incomingSession.on('failed', (data) => handleSessionEnd(data.cause));
    incomingSession.on('ended', () => handleSessionEnd());
  };

  const makeCall = async () => {
    if (!ua || hasOutgoingCall || hasIncomingCall) return;

    setLoading(true);
    setHasOutgoingCall(true);

    if (!audioPlayer.isPlaying('ringback')) {
      audioPlayer.play('ringback');
    }

    const outgoingSession = ua.call(targetUri, {
      pcConfig: {
        rtcpMuxPolicy: 'negotiate',
        iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }],
      },
      mediaConstraints: { audio: true, video: true },
      rtcOfferConstraints: { offerToReceiveAudio: 1, offerToReceiveVideo: 1 },
    });

    outgoingSession.on('connecting', () => {
      setSession(outgoingSession);
      setLoading(false);
    });

    outgoingSession.on('progress', () => {
      if (!audioPlayer.isPlaying('ringback')) {
        audioPlayer.play('ringback');
      }
    });
    outgoingSession.on('failed', ({ cause }) => handleSessionEnd(cause));
    outgoingSession.on('ended', () => handleSessionEnd());
    outgoingSession.on('accepted', () => {
      audioPlayer.stop('ringback');
      audioPlayer.play('answered');
      setCallHasAcceptedByOtherSide(true);
    });

    outgoingSession.on('confirmed', () => {
      const connection = outgoingSession.connection;
      if (connection) {
        connection.ontrack = (event) => {
          const remoteStream = event.streams[0];
          const audioElement = document.getElementById('remoteAudio') as HTMLAudioElement;
          if (audioElement && remoteStream) {
            audioElement.srcObject = remoteStream;
            audioElement
              .play()
              .catch((error) => console.error('Ошибка воспроизведения аудио:', error));
          }
        };
      }
    });
  };

  const handleSessionEnd = (cause?: string) => {
    if (cause) {
      console.warn(`Call ended with reason: ${cause}`);
    }

    if (audioPlayer.isPlaying('ringback')) {
      audioPlayer.stop('ringback');
    }
    if (audioPlayer.isPlaying('ringing')) {
      audioPlayer.stop('ringing');
    }

    handleHideModal();
    resetState();
  };

  const answerIncomingCall = async () => {
    if (!session) return;

    session.answer({
      pcConfig: {
        rtcpMuxPolicy: 'negotiate',
        iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }],
      },
    });

    setCallHasAcceptedByOtherSide(true);
  };

  const endCall = () => {
    if (session) {
      session.terminate();
      handleSessionEnd();
    }
  };

  useEffect(() => {
    return () => {
      session?.terminate();
      setSession(null);
    };
  }, []);

  return {
    makeCall,
    answerIncomingCall,
    endCall,
    ua,
    hasOutgoingCall,
    hasIncomingCall,
    callHasAcceptedByOtherSide,
    loading,
  };
};
