import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {defineMessages, useIntl} from 'react-intl'
import {useNavigate} from 'react-router'
import {useModal} from '../../../../app/hooks/useModal'
import {ReactComponent as ReceivedIcon} from '../../../../assets/icons/received-mini.svg'
import {ReactComponent as SentIcon} from '../../../../assets/icons/sent-mini.svg'
import {RoutePath} from '../../../../config/routes/constants/routePath'
import {Route} from '../../../../config/routes/enums/route'
import {MODAL_TYPES} from '../../../../layouts/modals/ModalsProvider'
import {PageWrapper} from '../../../../layouts/page-wrapper/PageWrapper'
import {NoDevices} from '../../../../layouts/status-layouts/no-devices/NoDevices'
import {DeviceTable} from '../../../../layouts/tables/device-table/DeviceTable'
import {formatPhoneNumber} from '../../../../sdk/formatters/format-phone-number'
import {useDevices} from '../../../../sdk/hooks/use-devices/useDevices'
import {ScreenDevices, useLessThanDeviceScreen,} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import {Card} from '../../../../shared/ui-kit-2/data-display/card/Card'
import {Counter} from '../../../../shared/ui-kit-2/data-display/counter/Counter'
import {PaginationMobile} from '../../../../shared/ui-kit-2/data-display/pagination/PaginationMobile'
import {Button} from '../../../../shared/ui-kit-2/inputs/button/Button'
import {useUserSubscription} from '../../../../store/hooks/useUserSubscription'
import styles from './styles.module.scss'
import clsx from 'clsx'
import {StatusNetwork} from "../../../../shared/ui-kit-2/data-display/status-network/StatusNetwork";
import {StatusSubscription} from "../../../../shared/ui-kit-2/data-display/status-subscription/StatusSubscription";
import {changeUrlParams} from "../../../../shared/lib/utils/change-url-params/change-url-params";
import {getUrlParams} from "../../../../shared/lib/utils/get-url-params/get-url-params";
import {DongleFlags} from "../../../../sdk/datagates/types/dongle/_crud/list";
import {dongleGetDatagate} from "../../../../sdk/datagates/api/dongle";
import _ from 'lodash';
import {useSiteVersion} from "../../../../app/hooks/useSiteVersion";

const DeviceListPageMessages = defineMessages({
  title: {
    id: 'DeviceListPageMessages.title',
    defaultMessage: 'Devices',
  },
  activateDevices: {
    id: 'DeviceListPageMessages.activateDevices',
    defaultMessage: 'Activate Device',
  },
  redirections: {
    id: 'DeviceListPageMessages.redirections',
    defaultMessage: 'Redirections',
  },
  autoReplies: {
    id: 'DeviceListPageMessages.autoReplies',
    defaultMessage: 'Auto replies',
  },
  device: {
    id: 'DeviceListPageMessages.device',
    defaultMessage: 'device',
  },
  devices: {
    id: 'DeviceListPageMessages.devices',
    defaultMessage: 'devices',
  },
  buyDevice: {
    id: 'DeviceListPageMessages.buyDevice',
    defaultMessage: 'Buy Device',
  },
  online: {
    id: 'DeviceListPageMessages.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'DeviceListPageMessages.offline',
    defaultMessage: 'Offline',
  },
  received: {
    id: 'DeviceListPageMessages.received',
    defaultMessage: 'Received: {count}',
  },
  sent: {
    id: 'DeviceListPageMessages.sent',
    defaultMessage: 'Sent: {count}',
  },
  sms: {
    id: 'DeviceListPageMessages.sms',
    defaultMessage: 'SMS',
  },
  calls: {
    id: 'DeviceListPageMessages.calls',
    defaultMessage: 'Calls',
  },
  details: {
    id: 'DeviceListPageMessages.details',
    defaultMessage: 'Details',
  },
  active: {
    id: 'DeviceTableRows.active',
    defaultMessage: 'active',
  },
  inactive: {
    id: 'DeviceTableRows.inactive',
    defaultMessage: 'inactive',
  },
})

export const DeviceListPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { isSubscriptionExpired } = useUserSubscription()
  const urlParams = getUrlParams()
  const [settings, setSettings] = useState({
    page: +urlParams?.page || 1,
    sortBy: urlParams?.sortBy || 'dongle_id',
    orderBy: urlParams?.orderBy || 'desc',
    limit: +urlParams?.limit || 50
  })
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const { handleOpenModal } = useModal()

  const handleChangeSettings = (fields: Object) => {
    let isChanged = false;
    const copiedSettings = { ...settings };

    for (let key in fields) {
      // @ts-ignore
      if (copiedSettings.hasOwnProperty(key) && settings[key] !== settings[key]) {
        // @ts-ignore
        copiedSettings[key] = fields[key];
        isChanged = true;
      }
    }

    if (isChanged) {
      setSettings(copiedSettings);
    }
  }

  const {
    devices,
    totalCount,
    handleFetchDevices,
    handleDeleteDevice,
    handleSwitchHotspot,
    loading,
    initialLoading,
    setDevices,
  } = useDevices({
    page: settings.page,
    limit: settings.limit,
    sortBy: settings.sortBy,
    orderBy: settings.orderBy,
  })

  const {
    loading: inactiveDevicesLoading,
    totalCount: inactiveDevicesTotalCount,
  } = useDevices({
    page: 0,
    limit: 0,
    takeAll: true,
    isActive: false,
  })

  useEffect(() => {
    changeUrlParams({
      page: settings.page,
      limit: settings.limit,
      sortBy: settings.sortBy,
      orderBy: settings.orderBy,
    })
  }, [settings]);

  const sliceDevices = devices.slice(0, 3000)

  const toBuyNewDevice = () => {
    handleOpenModal({
      type: MODAL_TYPES.BUY_NEW_DEVICE,
    })
  }

  const toActivateNewDevice = () => navigate(RoutePath[Route.ActivateDevice])

  const deviceWithPhoneNumberChangeLoading = useMemo(() =>
    devices.find(({ dongleFlags }) => dongleFlags?.find(flag => flag.dongleFlagId === DongleFlags.MANUAL_PHONE_DETERMINING))?.dongleId
  , [devices]);

  const timerRef = useRef(null);

  const handleFetchDevice = async (deviceId: number) => {
    try {
      const { data } = await dongleGetDatagate(null, [{ name: 'dongle_id', value: `${deviceId}` }])
      return data;
    } catch {}
  }

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, [settings.page]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (deviceWithPhoneNumberChangeLoading) {
      // @ts-ignore
      timerRef.current = setInterval(async () => {
        const updatedDongle = await handleFetchDevice(deviceWithPhoneNumberChangeLoading);
        if (updatedDongle && !updatedDongle.dongleFlags?.find(flag => flag.dongleFlagId === DongleFlags.MANUAL_PHONE_DETERMINING)) {
          setDevices(prev => {
            const copyArr = [...prev];
            const indexOfUpdatedDongle = copyArr.findIndex(({ dongleId }) => dongleId === updatedDongle.dongleId)
            copyArr[indexOfUpdatedDongle] = updatedDongle;
            return copyArr;
          })
        }
      }, 10000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [deviceWithPhoneNumberChangeLoading]);

  const DeviceListMobile = () => {
    const handleOpenDeviceInfo = (device: any) => {
      handleOpenModal({
        type: MODAL_TYPES.DEVICE_DETAILS,
        props: {
          deviceId: device.dongleId,
          handleFetchDevices,
          handleDeleteDevice,
          handleSwitchHotspot,
        },
      })
    }

    return devices.length > 0 ? (
      <>
        <div className={styles.DeviceListMobile}>
          {devices.map(device => (
            <div
              key={device.dongleId}
              className={styles.DeviceListMobile__Item}
            >
              <div className={styles.DeviceListMobile__Top}>
                <div className={styles.DeviceListMobile__TopRow}>
                  <div className={styles.DeviceListMobile__Title}>
                    {device.name}
                  </div>
                  <StatusNetwork
                    isOnline={device.isOnline}
                    dongleSignalQualityId={device.dongleSignalQualityId}
                  />
                </div>
                <div className={styles.DeviceListMobile__Number}>
                  {formatPhoneNumber(device.phoneNumber)}
                </div>
              </div>

              <div className={styles.DeviceListMobile__Divider} />

              <div className={styles.DeviceListMobile__Cols}>
                <div className={styles.DeviceListMobile__Col}>
                  <div className={styles.DeviceListMobile__Label}>
                    {intl.formatMessage(DeviceListPageMessages.sms)}
                  </div>

                  <div className={styles.DeviceListMobile__Row}>
                    <div className={styles.DeviceListMobile__Icon}>
                      <SentIcon />
                    </div>
                    {intl.formatMessage(DeviceListPageMessages.sent, {
                      count: device.smsOutgoing,
                    })}
                  </div>

                  <div className={styles.DeviceListMobile__Row}>
                    <div className={styles.DeviceListMobile__Icon}>
                      <ReceivedIcon />
                    </div>
                    {intl.formatMessage(DeviceListPageMessages.received, {
                      count: device.smsIncoming,
                    })}
                  </div>
                </div>

                <div className={styles.DeviceListMobile__Col}>
                  <div className={styles.DeviceListMobile__Label}>
                    {intl.formatMessage(DeviceListPageMessages.calls)}
                  </div>

                  <div className={styles.DeviceListMobile__Row}>
                    <div className={styles.DeviceListMobile__Icon}>
                      <SentIcon />
                    </div>
                    {intl.formatMessage(DeviceListPageMessages.sent, {
                      count: device.callOutgoing,
                    })}
                  </div>

                  <div className={styles.DeviceListMobile__Row}>
                    <div className={styles.DeviceListMobile__Icon}>
                      <ReceivedIcon />
                    </div>
                    {intl.formatMessage(DeviceListPageMessages.received, {
                      count: device.callIncoming,
                    })}
                  </div>
                </div>
              </div>

              <div className={styles.DeviceListMobile__Button}>
                <StatusSubscription isTariffPackageActive={device.isTariffPackageActive}/>
                <Button
                  text={intl.formatMessage(DeviceListPageMessages.details)}
                  onClick={() => handleOpenDeviceInfo(device)}
                  variant={'greenTextOutlined'}
                  icon={'caret'}
                  reverse
                />
              </div>
            </div>
          ))}
        </div>
        <PaginationMobile
          currentPage={settings.page}
          totalCount={totalCount}
          onPageChange={(page, limit) => {
            handleChangeSettings({ page, limit })
          }}
          itemsPerPage={settings.limit}
        />
      </>
    ) : (
      <NoDevices />
    )
  }

  return (
    <PageWrapper>
      <Card additionalClassNames={[styles.CardAdditional]}>
        <div className={styles.Header}>
          <div className={styles.Col}>
            <div className={styles.Title}>
              {intl.formatMessage(DeviceListPageMessages.title)}
            </div>
            {!loading && (
              <Counter
                count={totalCount}
                text={intl.formatMessage(
                  totalCount === 1
                    ? DeviceListPageMessages.device
                    : DeviceListPageMessages.devices,
                )}
                size={'small'}
              />
            )}
          </div>
          <div className={clsx(styles.Col, styles.Col_row)}>
            <Button
              variant={'greenTextOutlined'}
              text={intl.formatMessage(DeviceListPageMessages.activateDevices)}
              icon={'activate'}
              onClick={toActivateNewDevice}
              additionalClassNames={[styles.Button]}
              disabled={isSubscriptionExpired || isMobile}
            />
            <Button
              text={intl.formatMessage(DeviceListPageMessages.buyDevice)}
              icon={'buy'}
              onClick={toBuyNewDevice}
              additionalClassNames={[styles.Button]}
              disabled={isSubscriptionExpired}
            />
          </div>
        </div>

        {isMobile ? (
          <DeviceListMobile />
        ) : (
          <DeviceTable
            devices={sliceDevices}
            totalCount={totalCount}
            inactiveDevicesTotalCount={inactiveDevicesTotalCount}
            loading={initialLoading || loading || inactiveDevicesLoading}
            handleFetchDevices={handleFetchDevices}
            handleDeleteDevice={handleDeleteDevice}
            handleSwitchHotspot={handleSwitchHotspot}
            settings={settings}
            handleChangeSettings={handleChangeSettings}
          />
        )}
      </Card>
    </PageWrapper>
  )
}
